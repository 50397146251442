<template>
    <div class="home-page">
        <div class="banner">
            <div class="img-warp">
                <img src="https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665286790973.png" alt="" />
            </div>
        </div>

        <section class="home-content" id="homeContent">
            <div class="insure-card" ref="insure">
                <div class="right-card">
                    <!-- <div class="total-money">
                        <span class="money">99</span>
                        <span class="unit">元/人</span>
                    </div> -->
                    <div class="submit-btn" @click="$router.push('/xsbn/insure_page')">
                        立即投保
                    </div>
                </div>
            </div>
            <div class="scroll-card">
                <div class="sticky-card">
                    <nav class="nav-list">
                        <div class="nav-item" :class="navIndex == 1 ? 'active' : ''" @click="handleChangeNav(1, '#insureder')">
                            <div>保障内容</div>
                            <span class="badge"></span>
                        </div>
                        <div class="nav-item" :class="navIndex == 2 ? 'active' : ''" @click="handleChangeNav(2, '#product')">
                            <div>参保流程</div>
                            <span class="badge"></span>
                        </div>
                        <div class="nav-item" :class="navIndex == 3 ? 'active' : ''" @click="handleChangeNav(3, '#claimCases')">
                            <div>常见问题</div>
                            <span class="badge"></span>
                        </div>
                        <div class="nav-item" :class="navIndex == 4 ? 'active' : ''" @click="handleChangeNav(4, '#question')">
                            <div>理赔案例</div>
                            <span class="badge"></span>
                        </div>
                    </nav>
                    <div class="right-desc" v-show="buttonFlag">
                        <div class="total-money">
                            <span class="money">139</span>
                            <span class="unit">元/人</span>
                        </div>
                        <div class="submit-btn" @click="$router.push('/xsbn/insure_page')">
                            立即投保
                        </div>
                    </div>
                </div>
                <header class="common-header" id="insureder">
                    <div class="title pad-left">
                        <span>保障内容</span>
                    </div>
                </header>
                <div class="content-img">
                    <img src="https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665287032174.png" alt="" />
                    <div class="tips">
                        <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646291498169.png" alt="" />
                        备注：未尽事宜，请以
                        <span>《参保须知》</span>
                        和
                        <span>《理赔须知》</span>
                        为准
                    </div>
                </div>
                <div class="content-button">
                    <div class="detail-button">
                        <span class="button-text" @click="handleShow">查看保障详情</span>
                        <img class="button-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646028321794.png" alt="" />
                    </div>
                </div>

                <header class="common-header" style="padding: 56px 0 10px" id="product">
                    <div class="title pad-left">
                        <span>参保流程</span>
                    </div>
                </header>
                <div class="progress">
                    <p>只需三步即可完成投保</p>
                    <img src="https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665287102078.png" alt="" />
                </div>

                <header class="common-header" style="padding: 56px 0 0" id="claimCases">
                    <div class="title pad-left">
                        <span>常见问题</span>
                    </div>
                </header>

                <div class="question">
                    <el-collapse accordion>
                        <el-collapse-item v-for="(item, index) of question" :key="index">
                            <template slot="title">
                                <div class="collapse-title">
                                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                    <span>{{ item.title }}</span>
                                </div>
                            </template>
                            <div class="collapse-content">
                                <img class="article-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                <div class="article">{{ item.desc }}</div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>

                <div class="content-button" style="margin-top: 44px">
                    <div class="detail-button" @click="show = true">
                        <span class="button-text">查看更多问题</span>
                        <img class="button-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646028321794.png" alt="" />
                    </div>
                </div>

                <header class="common-header" style="padding: 56px 0 0" id="question">
                    <div class="title pad-left">
                        <span>理赔案例</span>
                    </div>
                </header>
                <div class="example">
                    <p>仅做演示使用，不作为理赔依据</p>
                    <div class="example-list">
                        <!-- <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653919306175.png" alt="" />
                        <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653919308226.png" alt="" />

                        <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653919310726.png" alt="" /> -->
                        <img src="https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665287137333.png" alt="">
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer">
            <img class="footer-bg" src="https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665287240184.png" alt="">
            <img class="footer-content" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1653966423886.png" alt="" />
            <!-- <div class="img-warp">
                <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1650437326486.png" alt="">
            </div> -->
        </footer>

        <!-- 查看常见问题 -->
        <el-dialog :visible.sync="show" width="900px" append-to-body :show-close="false">
            <div class="dialog-content">
                <header class="dialog-header">
                    <div class="title">
                        <span>常见问题</span>
                        <img class="del" @click="show = false" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646295309622.png" alt="" />
                    </div>
                </header>
                <div class="question-list">
                    <el-collapse accordion>
                        <el-collapse-item v-for="(item, index) of common_data" :key="index">
                            <template slot="title">
                                <div class="collapse-title">
                                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                    <span>{{ item.title }}</span>
                                </div>
                            </template>
                            <div class="collapse-content">
                                <img class="article-img" src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646044614662.png" alt="" />
                                <div class="article" v-html="item.text"></div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            navIndex: 1,
            marginTop: "",
            question: [
                {
                    id: 1,
                    title: "什么是“西双版纳惠民保”？",
                    desc:
                        "“西双版纳惠民保”是根据版纳州居民的医疗和生活水平，专为版纳人民定制、紧密衔接社会医疗保险的一款商业医疗保险产品，以普惠价格为版纳基本医疗保险参保人员提供百万级保险保障和便民化健康服务，提升版纳居民的健康保障水平。",
                },
                {
                    id: 2,
                    title: "哪些人可以参保“西双版纳惠民保”？",
                    desc:
                        "全体参加版纳州基本医疗保险的在保人员，没有年龄、健康状况、职业等其他条件限制，均可参保“西双版纳惠民保”",
                },
                {
                    id: 3,
                    title: "可以为家人购买吗？",
                    desc:
                        "可以。“西双版纳惠民保”为惠及更多版纳市民，不论年龄职业健康状况，只要是版纳州基本医疗保险参保人即可购买；您可在“西双版纳惠民保”微信公众号投保添加家人为被保险人，即可为家人购买。",
                },
                {
                    id: 4,
                    title: "参保时需要健康告知或体检吗？",
                    desc: "无需进行健康告知或进行体检。",
                },
                {
                    id: 5,
                    title: "参保人年龄对保费有影响吗？",
                    desc:
                        "没有影响，不限制年龄（新生儿需≥28天）老少同价统一保费139元。",
                },
                {
                    id: 6,
                    title: "购买了“西双版纳惠民保”，何时生效？",
                    desc: "在集中投保期投保（2022年12月12日至2023年2月28日），2023年3月1日零时生效。",
                },
            ],
            common_data: [
                {
                    title: "西双版纳“惠民保”的销售期和保障期是？	",
                    text:
                        "投保期：2022年12月12日至2023年2月28日。投保期结束后不可再投保。" +
                        "<br/>" +
                        "保障期：2023年3月1日0时起-2024年2月29日24时止。",
                },
                {
                    title: "什么是“西双版纳惠民保”？",
                    text:
                        "“西双版纳惠民保”是根据版纳州的医疗和生活水平，由西双版纳州保险行业协会指导，专为版纳州人民定制、紧密衔接社会医疗保险的一款商业医疗保险产品，以普惠价格为版纳基本医疗保险参保人员提供百万级保险保障和便民化健康服务，提升版纳居民的健康保障水平。",
                },
                {
                    title: "哪些人可以参保“西双版纳惠民保”？",
                    text:
                        "全体参加版纳州基本医疗保险的在保人员，没有年龄、健康、职业等限制，均可参保。",
                },
                {
                    title: "可以为家人投保吗？",
                    text:
                        "可以。“西双版纳惠民保”为惠及更多版纳市民，要是西双版纳基本医疗保险参保人即可购买；您可在“西双版纳惠民保”微信公众号投保添加家人为被保险人，即可为家人购买。",
                },
                {
                    title: "参保人年龄对保费是否有影响？",
                    text:
                        "没有影响，不限制年龄（新生儿需≥28天）老少同价统一保费139元。",
                },
                {
                    title: "未成年人可以参保“西双版纳惠民保”吗？",
                    text:
                        "可以，被保险人是未成年人时，投保人必须为被保险人的法定监护人，且其法定监护人须作为主被保险人与其同时参保。（新生儿需≥28 天）。",
                },
                {
                    title: "参保时需要健康告知或体检吗？",
                    text: "无需进行健康告知或进行体检",
                },
                {
                    title: "我生过病，可以买吗？",
                    text:
                        "可以投保，无需体检、无需健康告知。全体参加版纳州基本医疗保险的在保人员，均可参加该项目。但区分既往症和非既往症人群，被保险人在首次投保保单生效前如已患恶性肿瘤（含白血病、淋巴瘤）重大疾病，并因此导致在保险期间内发生住院的医疗费用，本产品不予赔付。请您在投保前详细阅读《产品说明书》。",
                },
                {
                    title: "投保有户籍限制吗，不是西双版纳的户籍可以买吗？",
                    text: "本产品不限户籍，但需参保西双版纳州基本医疗的在保市民",
                },
                {
                    title:
                        "社会基本医疗保险是什么？",
                    text: "包括职工基本医疗保险、职工大额医疗费用补助、城乡居民基本医疗保险、城乡居民大病保险、公务员医疗补助、医疗救助等政府举办的基本医疗保险。",
                },
                {
                    title: "版纳职工医保退休人员已经不需要再缴纳基本医疗保险费用，是否可以参保“西双版纳惠民保”？",
                    text: "可以参保。"
                },
                {
                    title: "有医保但不是版纳州医保，可以参保吗？",
                    text:
                        "不可以参保，“西双版纳惠民保”的参保人仅限版纳州基本医疗保险（含版纳州职工、居民）参保人。",
                },
                {
                    title: "已经有社会医疗保险，还有必要购买“西双版纳惠民保”吗？",
                    text:
                        "有必要，“西双版纳惠民保”保障的客户群体就是基本医疗保险参保的居民。是为版纳州基本医疗保险参保人（含城镇职工、城乡居民）专属量身定制的一款与基本医疗保障有效衔接的惠民商业补充医疗保险产品。对经医保报销后个人需要负担的金额以及在医保目录范围外的医保不予报销的个人自费金额，超过免赔额以上的部分，按照相应报销比例予以报销。尤其在重特大疾病医疗费用方面，可大幅降低医保报销后个人承担的医疗费用比例，极大地减轻“看病难、看病贵”的经济负担，有效防止因病致贫因病返贫。它是专门针对社会基本医疗保险的良好补充。",
                },
                {
                    title: "“西双版纳惠民保”有等待期吗？",
                    text: "没有",
                },
                {
                    title: "免赔额是什么意思？",
                    text: "免赔额是指，一个保险期间内属于保险责任范围的，保险公司不予报销，需要被保险人自己承担的医疗费用金额。“西双版纳惠民保”医保范围内医疗费用年免赔额 8000 元，医保范围外医疗费用年免赔额10000 元。",
                },
                {
                    title: "我可以给自己买2份，获得两份保障吗？",
                    text: "不可以，本产品同一被保险人在一个保单年度，只能购买一份；多买无效。",
                },
                {
                    title: "“西双版纳惠民保”有赔付次数的限制吗？",
                    text:
                        "在保险期间符合“西双版纳惠民保”赔付范围的费用，在年度限额内可多次理赔，没有理赔次数限制，直至单个保险责任到达赔偿金额上限。",
                },
                {
                    title: "是出险人申请理赔还是投保人申请理赔？",
                    text: "都可以",
                },
                {
                    title: "如果在外地医院看病，除了医保报销部分，其他部分是否还可以报销？",
                    text:
                        "1、已参保版纳州城镇职工、城乡居民基本医疗保险及大病医疗险的，异地就医：医保范围内80%，医保范围外50%。" +
                        "<br>" + 
                        "2、未参保版纳州城镇职工大病医疗险的，并触发大病医疗保险的大病医疗保险起付线以上的部分在扣除责任免除的医疗费用及年免赔额后的剩余部分，异地就医：医保范围内按20%赔付，医保范围外按10%赔付，年度总累计责任赔偿限额为10万元"
                },
                {
                    title: "“西双版纳惠民保”，明年可以参保吗？",
                    text:
                        "请持续关注“西双版纳惠民保”微信公众号，并以“西双版纳惠民保”公众号公示的信息和通知为准。",
                },
            ],
            show: false,
            buttonFlag: false,
        };
    },
    mounted() {
        if (this.$route.query) {
            this.$route.query.pid && localStorage.setItem("pid", this.$route.query.pid);
            this.$route.query.cid && localStorage.setItem("cid", this.$route.query.cid);
            this.$route.query.smid && localStorage.setItem("smid", this.$route.query.smid);
        }
        this.$nextTick(() => {
            let that = this;
            document.addEventListener(
                "scroll",
                that.debunce(() => {
                    if (document.getElementById("homeContent")) {
                        let insurederTop =
                            document.getElementById("insureder").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let productTops =
                            document.getElementById("product").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let claimCasesTop =
                            document.getElementById("claimCases").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let questionTop =
                            document.getElementById("question").offsetTop +
                            document.getElementById("homeContent").offsetTop;
                        let top = 0;
                        if (document.documentElement) {
                            top = document.documentElement.scrollTop;
                        } else {
                            top = document.body.scrollTop;
                        }
                        if (top >= insurederTop - 80) {
                            that.buttonFlag = true;
                        } else {
                            that.buttonFlag = false;
                        }
                        if (top >= insurederTop && top < productTops - 100) {
                            that.navIndex = 1;
                        }
                        if (top >= productTops - 100 && top < claimCasesTop - 100) {
                            that.navIndex = 2;
                        }
                        if (top >= claimCasesTop - 100 && top < questionTop - 100) {
                            that.navIndex = 3;
                        }
                        if (top >= questionTop - 100) {
                            that.navIndex = 4;
                        }
                    }
                }, 100)
            );
        });
    },
    beforeDestroy() {
        let that = this;
        document.removeEventListener(
            "scroll",
            this.debunce(() => { }, 100)
        );
    },
    methods: {
        handleChangeNav(index, idName) {
            this.navIndex = index;
            document
                .querySelector(idName)
                .scrollIntoView({ block: "start", behavior: "smooth" });
        },
        // 查看保障详情
        handleShow() {
            window.open(
                "https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665470687473.pdf"
            );
        },
        debunce(callback, date = 2000) {
            let timer = null;
            return function () {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
                timer = setTimeout(callback, date);
            };
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-collapse-item__header {
    height: 96px;
}
.pad-left {
    padding-left: 44px;
}
.dialog-content {
    padding-bottom: 10px;
    .dialog-header {
        padding: 20px 0;
        .title {
            padding: 0 44px;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            &::before {
                content: "";
                width: 7px;
                height: 28px;
                background: #a72c1c;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            span:nth-child(1) {
                color: #333333;
                font-size: 29px;
                line-height: 29px;
                font-weight: 600;
            }
            .del {
                cursor: pointer;
                width: 24px;
                height: 24px;
            }
        }
    }
    .question-list {
        height: 530px;
        overflow-y: auto;
        margin: 25px 44px 0;
        .collapse-title {
            display: flex;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
                position: relative;
                top: 2px;
            }
            span {
                margin-left: 22px;
                font-size: 20px;
                font-weight: 600;
                color: #333333;
            }
        }
        .collapse-content {
            display: flex;
            .article-img {
                width: 24px;
                height: 24px;
                position: relative;
                top: 2px;
            }
            .article {
                margin-left: 22px;
                font-size: 20px;
                font-weight: 400;
                color: #999999;
            }
        }
    }
}
.home-page {
    min-height: calc(100vh - 80px - 60px);
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    .banner {
        width: 100%;
        min-width: 1200px;
        max-width: 1920px;
        // overflow: hidden;
        height: 529px;
        margin: 0 auto;
        position: relative;
        .img-warp {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            img {
                width: 1750px;
                position: absolute;
                left: -278px;
            }
        }
    }
    .home-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-top: 190px;
    }
    .insure-card {
        width: 1200px;
        height: 823px;
        background: #ffffff;
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background:url(https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665470730302.png)
            no-repeat;
            
        background-size: 100% 100%;
        background-position: 0 0;
        position: relative;
        .right-card {
            position: absolute;
            width: 223px;
            right: 44px;
            bottom: 33px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
        }
        .total-money {
            display: flex;
            align-items: flex-end;
            .money {
                font-size: 53px;
                font-weight: 800;
                color: #a72c1c;
                line-height: 43px;
            }
            .unit {
                font-size: 29px;
                font-weight: 500;
                color: #a72c1c;
                line-height: 29px;
            }
        }
        .submit-btn {
            margin-top: 20px;
            cursor: pointer;
            width: 233px;
            height: 56px;
            background: linear-gradient(180deg, #c44534 0%, #a72c1c 100%);
            border-radius: 4px;
            font-size: 27px;
            font-weight: 600;
            color: #ffffff;
            line-height: 56px;
            text-align: center;
        }
    }
    .scroll-card {
        margin-top: 44px;
        width: 1200px;
        background: #ffffff;
        box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        .sticky-card {
            position: sticky;
            top: 80px;
            z-index: 99;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            box-shadow: 0px 1px 0px 0px rgba(216, 216, 216, 1),
                inset 0px 1px 0px 0px rgba(226, 226, 226, 1);

            .right-desc {
                display: flex;
                align-items: center;
                padding-right: 20px;
            }
            .submit-btn {
                cursor: pointer;
                margin-left: 15px;
                width: 200px;
                height: 44px;
                background: linear-gradient(180deg, #c44534 0%, #a72c1c 100%);
                border-radius: 3px;
                text-align: center;
                font-size: 22px;
                font-weight: 600;
                color: #ffffff;
                line-height: 44px;
            }
            .total-money {
                display: flex;
                align-items: flex-end;
                .money {
                    font-size: 51px;
                    font-weight: 800;
                    color: #a72c1c;
                    line-height: 45px;
                    position: relative;
                    top: 3px;
                }
                .unit {
                    margin-left: 2px;
                    font-size: 27px;
                    font-weight: 800;
                    color: #a72c1c;
                    line-height: 27px;
                }
            }
        }
        .nav-list {
            // background: #ffffff;
            // box-shadow: 0px 1px 0px 0px rgba(216, 216, 216, 1);
            display: flex;
            .nav-item:nth-child(1) {
                margin-left: 33px;
            }
            .nav-item {
                cursor: pointer;
                margin-left: 44px;
                position: relative;
                height: 67px;
                line-height: 67px;
                text-align: center;
                div {
                    font-size: 20px;
                    font-weight: 400;
                    color: #999999;
                }
                .badge {
                    position: absolute;
                    width: 10px;
                    height: 3px;
                    background: transparent;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all 0.3s;
                }
            }
            .nav-item.active {
                div {
                    font-weight: 600;
                    color: #a72c1c;
                }
                .badge {
                    width: 54px;
                    background: #a72c1c;
                }
            }
        }
        .common-header {
            margin-left: 33px;
            padding: 24px 0 20px;
            .title {
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: flex-end;
                &::before {
                    content: "";
                    width: 7px;
                    height: 25px;
                    background: #a72c1c;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                span:nth-child(1) {
                    color: #333333;
                    font-size: 29px;
                    line-height: 29px;
                    font-weight: 600;
                }
            }
        }
        .content-img {
            padding: 0 33px 0;
            & > img {
                width: 100%;
            }
            .tips {
                padding-top: 20px;
                font-size: 20px;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
                span {
                    color: #a72c1c;
                }
            }
        }
        .content-button {
            margin-top: 33px;
            display: flex;
            justify-content: center;
            .detail-button {
                cursor: pointer;
                width: 289px;
                height: 67px;
                border-radius: 47px;
                border: 2px solid #a72c1c;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                .button-text {
                    font-size: 22px;
                    font-weight: 400;
                    color: #a72c1c;
                }
                .button-img {
                    position: absolute;
                    width: 26px;
                    height: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 33px;
                }
            }
        }
        .progress {
            margin: 11px 78px 0;
            p {
                font-size: 20px;
                font-weight: 400;
                color: #999999;
            }
            img {
                margin-top: 33px;
                width: 100%;
                height: 330px;
            }
        }
        .question {
            margin: 25px 78px 0;
            .collapse-title {
                display: flex;
                align-items: center;

                img {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    top: 2px;
                }
                span {
                    margin-left: 22px;
                    font-size: 20px;
                    font-weight: 600;
                    color: #333333;
                }
            }
            .collapse-content {
                display: flex;
                .article-img {
                    width: 22px;
                    height: 22px;
                    position: relative;
                    top: 6px;
                }
                .article {
                    margin-left: 22px;
                    font-size: 20px;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }
        .example {
            padding: 10px 78px 36px;
            p {
                font-size: 20px;
                font-weight: 400;
                color: #999999;
            }
            .example-list {
                margin-top: 44px;
                display: flex;
                overflow-x: auto;
                img {
                    margin-left: 31px;
                    width: 100%;
                    height: 902px;
                }
                img:nth-child(1) {
                    margin-left: 0;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 10px;
                }
                &::-webkit-scrollbar-track {
                    background: #fff;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #a72c1c;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #a72c1c;
                }
                &::-webkit-scrollbar-corner {
                    background: #a72c1c;
                }
            }
        }
    }
    .footer {
        margin-top: 21px;
        display: flex;
        height: 651px;
        position: relative;

        .footer-bg {
            padding: 0 292px;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
        }
        .footer-content {
            width: 100%;
        }
        .img-warp {
            width: 1200px;
            margin: 0 auto;
            position: relative;
            img {
                width: 1920px;
                position: absolute;
                left: -360px;
            }
        }
    }
}
/deep/ .el-collapse {
    border-top: 0;
}
</style>
